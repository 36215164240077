*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
/* background-color: hsl(146deg 30% 92%); */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  background-color: white;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.contact_button {
  background-color: #000;
  border: 2px solid #000;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.contact_icon {
  display: block;
  width: 70px;
  padding: 0;
  margin: 0;
}
