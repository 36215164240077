.gallery_container {
  padding: 2rem 2rem 4rem;
}

.gallery_label {
  margin: 2rem auto 4rem;
  width: 290px;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  border-bottom: 2px solid black;
}

.gallery {
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

.gallery_category {
  position: relative;
  overflow: hidden;
}

.category_cover {
  display: block;
  height: 250px;
  width: 350px;
  object-fit: cover;
}

.category_cover_fix {
  display: block;
  height: 250px;
  width: 350px;
  object-fit: cover;
  object-position: 0 1px;
}

.category_cover_custom {
  display: block;
  height: 250px;
  width: 350px;
  object-fit: cover;
  object-position: 0 -170px;
}

.category_cover:hover,
.category_cover_custom:hover,
.category_cover_fix:hover {
  transform: scale(2);
  transition: 0.4s ease-in-out;
}

.gallery_category:hover .category_cover,
.gallery_category:hover .category_cover_custom,
.gallery_category:hover .category_cover_fix {
  transform: scale(1.2);
  transition: 0.5s ease-in-out;
}

.category_button {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2rem;
  border: none;
  color: white;
  filter: opacity(100%);
  background-color: hsla(0, 0%, 0%, 0.431);
}
