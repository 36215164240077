.img_container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 2rem 2rem;
}

.img_carousel {
  position: relative;
  height: 250px;
  width: 250px;
  max-height: 300px;
  max-width: 300px;
}

.carousel_container {
  position: relative;

  width: 100%;
  height: 100%;
}

.carousel_track {
  padding: 0;
  margin: 0;
  list-style: none;
}

.carousel_slide {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.carousel_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: white;
}

.carousel_backward {
  left: -40px;
}

.carousel_forward {
  right: -40px;
}

.creation {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel_nav {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  gap: 1rem;
}

.carousel_indicator {
  border: none;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: white;
}

.carousel_indicator.active {
  background-color: black;
}

@media (min-width: 500px) {
  .img_container {
    width: 300px;
    height: 300px;
  }
}