.container {
  padding: 2rem;
  background-color: hsl(12deg 41% 31%);
}

.about_container {
  margin: 2rem auto;
  max-width: 820px;
}

.img_container {
  margin: 1rem auto 1rem;
  max-width: 1200px;
}

.brandon {
  display: block;
  width: 100%;
}

.message_container {
  margin: 0 auto;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  max-width: 900px;
}

.message {
  padding-top: 1rem;
}

/* @media (min-width: 1000px) {
  .about_container {
    display: flex;
    gap: 2rem;
  }

  .img_container {
    min-width: 400px;
  }

} */
